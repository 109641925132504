<template>
    <TransitionRoot
        as="template"
        :show="open"
        class="hiorg-vue-app"
    >
        <Dialog
            as="div"
            class="tw:fixed tw:z-10 tw:inset-0 tw:overflow-y-auto"
            @close="open = false"
        >
            <div
                class="tw:flex tw:justify-center tw:min-h-screen tw:pt-4
                tw:px-4 tw:pb-20 tw:text-center tw:sm:block tw:sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="tw:ease-out tw:duration-300"
                    enter-from="tw:opacity-0"
                    enter-to="tw:opacity-100"
                    leave="tw:ease-in tw:duration-200"
                    leave-from="tw:opacity-100"
                    leave-to="tw:opacity-0"
                >
                    <DialogOverlay class="tw:fixed tw:inset-0 tw:bg-gray-500/75 tw:transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="tw:hidden tw:sm:inline-block tw:sm:align-middle tw:sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="tw:ease-out tw:duration-300"
                    enter-from="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                    enter-to="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                    leave="tw:ease-in tw:duration-200"
                    leave-from="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                    leave-to="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                >
                    <div
                        class="tw:inline-block tw:align-bottom tw:bg-white tw:rounded-lg tw:px-4 tw:pt-5 tw:pb-4
                        tw:text-left tw:overflow-hidden tw:shadow-xl tw:transform tw:transition-all tw:sm:my-8
                        tw:sm:align-middle tw:sm:max-w-lg tw:sm:w-full tw:sm:p-6"
                    >
                        <div class="tw:sm:flex tw:sm:items-start">
                            <div
                                class="tw:mx-auto tw:shrink-0 tw:flex tw:items-center tw:justify-center tw:h-12
                                    tw:w-12 tw:rounded-full tw:bg-red-100 tw:sm:mx-0 tw:sm:h-10 tw:sm:w-10"
                            >
                                <ExclamationTriangleIcon
                                    class="tw:inline tw:size-6 tw:text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="tw:mt-3 tw:text-center tw:sm:mt-0 tw:sm:ml-4 tw:sm:text-left">
                                <DialogTitle
                                    as="h3"
                                    class="tw:text-lg tw:leading-6 tw:font-medium tw:text-gray-900 tw:mt-2"
                                    v-html="titel"
                                />
                                <div class="tw:mt-2">
                                    <p
                                        class="tw:text-sm tw:text-gray-500"
                                        v-html="content"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="tw:mt-5 tw:sm:mt-4 tw:sm:flex tw:sm:flex-row-reverse">
                            <button
                                id="btn-function"
                                type="button"
                                class="tw:w-full tw:inline-flex tw:justify-center tw:rounded-md tw:border
                                tw:border-transparent tw:shadow-xs tw:px-4 tw:py-2 tw:font-medium
                                tw:text-white tw:sm:ml-3 tw:sm:w-auto tw:sm:text-sm"
                                @click="clickFunction"
                                v-html="buttonText"
                            />
                            <button
                                type="button"
                                class="tw:mt-3 tw:w-full tw:inline-flex tw:justify-center tw:rounded-md
                                tw:border tw:border-gray-300 tw:hover:bg-gray-100 tw:shadow-xs tw:px-4 tw:py-2
                                tw:bg-white tw:font-medium tw:text-gray-700 tw:sm:mt-0 tw:sm:w-auto
                                tw:sm:text-sm"
                                @click="open = false"
                            >
                                Abbrechen
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ExclamationTriangleIcon} from "@heroicons/vue/24/outline";
import {computed} from "vue";

const props = defineProps<{
    modelValue: boolean;
    parentFunktion: () => void;
    titel: string;
    content: string;
    buttonText: string;
}>();

const emit = defineEmits<{
    (event: "update:modelValue", value: boolean): void;
}>();

const open = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});

const clickFunction = (): void => {
    open.value = false;
    props.parentFunktion();
};

</script>

<style>
    #btn-function {
        background-color: #fd5540 !important;
    }
    #btn-function:hover {
        background-color: #f44336 !important;
    }
</style>
