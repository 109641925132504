<template>
    <div>
        <a
            class="tw:mr-6 tw:text-hiorg-rot-500 tw:cursor-pointer"
            @click="openDialog"
        >
            <FontAwesomeIcon
                :icon="icon.icon"
                class="tw:h-4 tw:mr-0.5"
                :class="icon.farbe"
            />
            {{ aktion.text }}
        </a>
        <TransitionRoot
            as="template"
            :show="dialogIsOpen"
            class="hiorg-vue-app"
        >
            <Dialog
                class="tw:relative tw:z-10"
                :initial-focus="initialFocusRef"
                @close="closeDialog"
            >
                <TransitionChild
                    as="template"
                    enter="tw:ease-out tw:duration-300"
                    enter-from="tw:opacity-0"
                    enter-to="tw:opacity-100"
                    leave="tw:ease-in tw:duration-200"
                    leave-from="tw:opacity-100"
                    leave-to="tw:opacity-0"
                >
                    <div class="tw:fixed tw:inset-0 tw:bg-gray-500/75 tw:transition-opacity" />
                </TransitionChild>

                <div class="tw:fixed tw:inset-0 tw:z-10 tw:w-screen tw:overflow-y-auto">
                    <div
                        class="tw:flex tw:min-h-full tw:items-end tw:justify-center tw:p-4 tw:text-center
                               tw:sm:items-center tw:sm:p-0"
                    >
                        <TransitionChild
                            as="template"
                            enter="tw:ease-out tw:duration-300"
                            enter-from="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                            enter-to="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                            leave="tw:ease-in tw:duration-200"
                            leave-from="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                            leave-to="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                        >
                            <DialogPanel
                                class="tw:relative tw:transform tw:overflow-hidden tw:rounded-lg tw:bg-white
                                       tw:px-4 tw:pb-4 tw:pt-5 tw:text-left tw:shadow-xl tw:transition-all
                                       tw:sm:my-8 tw:sm:w-full tw:sm:max-w-lg tw:sm:p-6"
                            >
                                <div class="tw:absolute tw:right-0 tw:top-0 tw:pr-4 tw:pt-4">
                                    <button
                                        type="button"
                                        class="tw:rounded-md tw:bg-white tw:text-gray-400 tw:hover:text-gray-500
                                               tw:focus:outline-hidden tw:focus:ring-2 tw:focus:ring-indigo-500 tw:focus:ring-offset-2"
                                        @click="closeDialog"
                                    >
                                        <span class="tw:sr-only">Close</span>
                                        <XMarkIcon
                                            class="tw:size-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <div>
                                    <div class="tw:text-center">
                                        <DialogTitle
                                            as="h3"
                                            class="tw:text-base tw:font-semibold tw:leading-6 tw:text-gray-900"
                                        >
                                            Weiterleitung zur Umfrage
                                        </DialogTitle>
                                        <div class="tw:mt-2">
                                            <p class="tw:text-sm tw:text-gray-500">
                                                Prima, dass Du HiOrg-Server mitgestalten möchtest.
                                                Für die Umfrage leiten wir Dich auf unser eigenes Chat-System zum Verarbeiten der Antworten weiter.
                                                Dabei wird die Kennung Deiner Organisation übertragen.
                                                Davon abgesehen werden keine persönlichen Daten gespeichert.
                                                Falls Du das möchtest, kannst du im Verlauf des Chats eine Kontakt-E&#8209;Mail-Adresse angeben oder anonym bleiben.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tw:mt-5 tw:sm:mt-6">
                                    <button
                                        ref="initialFocus"
                                        type="button"
                                        class="tw:inline-flex tw:w-full tw:justify-center tw:rounded-md tw:bg-red-600
                                               tw:px-3 tw:py-2 tw:text-sm tw:font-semibold tw:text-white tw:shadow-xs tw:hover:bg-red-500
                                               tw:focus-visible:outline tw:focus-visible:outline-2 tw:focus-visible:outline-offset-2 tw:focus-visible:outline-indigo-600
                                               tw:sm:col-start-1"
                                        @click="openTypebot"
                                    >
                                        Zur Umfrage
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
        <TransitionRoot
            as="template"
            :show="typebotIsOpen"
            class="hiorg-vue-app"
        >
            <Dialog
                class="tw:relative tw:z-10"
                :initial-focus="typebotFrameRef"
                @close="closeTypebot"
            >
                <TransitionChild
                    as="template"
                    enter="tw:ease-out tw:duration-300"
                    enter-from="tw:opacity-0"
                    enter-to="tw:opacity-100"
                    leave="tw:ease-in tw:duration-200"
                    leave-from="tw:opacity-100"
                    leave-to="tw:opacity-0"
                >
                    <div class="tw:fixed tw:inset-0 tw:bg-gray-500/75 tw:transition-opacity" />
                </TransitionChild>

                <div class="tw:fixed tw:inset-0 tw:z-10 tw:w-screen tw:overflow-y-auto">
                    <div
                        class="tw:flex tw:min-h-full tw:items-end tw:justify-center tw:p-4 tw:text-center
                               tw:sm:items-center"
                    >
                        <TransitionChild
                            as="template"
                            enter="tw:ease-out tw:duration-300"
                            enter-from="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                            enter-to="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                            leave="tw:ease-in tw:duration-200"
                            leave-from="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                            leave-to="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                        >
                            <DialogPanel
                                class="tw:relative tw:transform tw:overflow-hidden tw:rounded-lg tw:bg-white
                                       tw:px-4 tw:pb-4 tw:pt-5 tw:text-left tw:shadow-xl tw:transition-all tw:h-[80svh]
                                       tw:sm:my-8 tw:sm:w-full tw:sm:max-w-screen-lg tw:sm:p-6"
                            >
                                <div class="tw:absolute tw:right-0 tw:top-0 tw:pr-4 tw:pt-4">
                                    <button
                                        type="button"
                                        class="tw:rounded-md tw:bg-white tw:text-gray-400 tw:hover:text-gray-500
                                               tw:focus:outline-hidden tw:focus:ring-2 tw:focus:ring-indigo-500 tw:focus:ring-offset-2"
                                        @click="closeTypebot"
                                    >
                                        <span class="tw:sr-only">Close</span>
                                        <XMarkIcon
                                            class="tw:size-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <iframe
                                    ref="typebotFrame"
                                    class="tw:w-full tw:h-full"
                                    :src="typebotUrl.toString()"
                                />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import axios, {type RawAxiosRequestHeaders} from "axios";
import {inject, ref, useTemplateRef} from "vue";
import type {InsightAktion} from "../../models/api/insight/InsightAktion";
import {XMarkIcon} from "@heroicons/vue/20/solid";
import {iconAktionTypeMapper} from "../../utilities/Insight/IconMapper";
import {keyContext} from "../../injection-keys";

const props = defineProps<{
    insightId: string;
    aktion: InsightAktion;
}>();

const initialFocusRef = useTemplateRef<HTMLElement>("initialFocus");
const typebotFrameRef = useTemplateRef<HTMLIFrameElement>("typebotFrame");

const icon = iconAktionTypeMapper(props.aktion.type);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const context = inject(keyContext)!;

const dialogIsOpen = ref(false);
const typebotIsOpen = ref(false);

const typebotUrl = new URL(props.aktion.url);
typebotUrl.searchParams.set("ov", context.organisation.getKuerzel());

function openDialog(): void {
    dialogIsOpen.value = true;
}

function closeDialog(): void {
    dialogIsOpen.value = false;
}

function openTypebot(): void {
    void axios.post(
        "/ajax/insights_tracking.php",
        {
            "insight-id": props.insightId,
            "aktion": "typebot-opened",
        },
        {
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
            } as RawAxiosRequestHeaders,
        },
    );

    closeDialog();
    typebotIsOpen.value = true;
}

function closeTypebot(): void {
    typebotIsOpen.value = false;
}
</script>
