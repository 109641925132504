<template>
    <div class="tw:flex tw:inset-auto tw:space-x-4">
        <div class="tw:text-12">
            <div
                v-if="simulationAktiv"
                class="tw:text-white tw:font-bold"
            >
                Benutzer simuliert:
            </div>
            <div
                v-else
                class="tw:flex tw:text-white tw:font-bold tw:justify-end"
            >
                Hallo, {{ vornameName }}
            </div>
            <div class="tw:text-right">
                <small class="tw:flex tw:text-white tw:justify-end">
                    {{ username }} @ ({{ ov }})
                </small>
            </div>
        </div>
        <Menu
            as="div"
            class="tw:relative"
        >
            <MenuButton
                class="tw:cursor-pointer tw:p-1 tw:rounded-full tw:h-11 tw:w-11 tw:items-center tw:justify-center
                       tw:flex tw:ring-1 tw:ring-offset-1 tw:ring-gray-400 tw:focus:outline-hidden tw:focus:ring-1
                       tw:focus:ring-offset-1 tw:focus:ring-white"
                :class="{'tw:bg-white': simulationAktiv}"
            >
                <img
                    v-if="simulationAktiv"
                    class="tw:h-8 tw:w-8 tw:rounded-full"
                    src="/static/content/pics/fontawesome/user-secret.svg"
                    alt=""
                >
                <img
                    v-else
                    class="tw:h-8 tw:w-8 tw:rounded-full"
                    src="/static/content/pics/fontawesome/user_ffffff.svg"
                    alt=""
                >
            </MenuButton>
            <transition
                enter-active-class="tw:transition tw:ease-out tw:duration-100"
                enter-from-class="tw:opacity-0 tw:scale-95"
                enter-to-class="tw:opacity-100 tw:scale-100"
                leave-active-class="tw:transition tw:ease-in tw:duration-75"
                leave-from-class="tw:opacity-100 tw:scale-100"
                leave-to-class="tw:opacity-0 tw:scale-95"
            >
                <MenuItems
                    class="tw:origin-top-right tw:absolute tw:z-10 tw:right-0 tw:mt-2 tw:w-48 tw:rounded-md tw:shadow-lg
                           tw:bg-white tw:ring-1 tw:ring-black/5 tw:focus:outline-hidden"
                >
                    <MenuItem
                        v-for="item in userNavigation"
                        :key="item.text"
                        v-slot="{ active }"
                    >
                        <div>
                            <a
                                :href="item.href"
                                style="color: black; text-decoration: none;"
                                :class="{'tw:hover:bg-hiorg-teal-100': active}"
                                class="tw:rounded-md tw:block tw:py-2 tw:px-4 tw:text-sm tw:hover:bg-hiorg-teal-100"
                            >
                                {{ item.text }}
                            </a>
                        </div>
                    </MenuItem>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script setup lang="ts">
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {inject} from "vue";
import {keyContext} from "../../injection-keys";

interface Navigation {
    href: string;
    text: string;
}

const userNavigation: Array<Navigation> = [
    {"href": "adresse.php", "text": "Mein Konto"},
    {"href": "logout.php", "text": "Logout"},
];
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const context = inject(keyContext)!;
const simulationAktiv: boolean = context.simulated;

if (simulationAktiv) {
    userNavigation.push({"href": `userliste.php?simuser=${context.user.getUserId()}`, "text": "Simulation beenden"});
}

const vornameName = `${context.user.getVorName()} ${context.user.getName()}`;
const username = context.user.getUserName();
const ov = context.organisation.getKuerzel();
</script>
