<!-- source: https://tailwindui.com/components/application-ui/feedback/alerts -->

<template>
    <div
        class="tw:rounded-md tw:bg-red-50 tw:p-4"
    >
        <div class="tw:flex">
            <div class="tw:shrink-0">
                <ExclamationTriangleIcon
                    class="tw:size-5 tw:text-red-400"
                    aria-hidden="true"
                />
            </div>
            <div class="tw:ml-3">
                <h3 class="tw:text-sm tw:font-medium tw:text-red-800">
                    {{ title }}
                </h3>
                <div class="tw:mt-2 tw:text-sm tw:text-red-700">
                    <p>{{ text }}</p>
                </div>
                <div class="tw:mt-4">
                    <div class="tw:-mx-2 tw:-my-1.5 tw:flex">
                        <button
                            type="button"
                            class="tw:bg-red-50 tw:px-2 tw:py-1.5 tw:rounded-md tw:text-sm tw:font-medium
                                    tw:text-red-800 tw:hover:bg-red-100 tw:focus:outline-hidden tw:focus:ring-2
                                    tw:focus:ring-offset-2 tw:focus:ring-offset-red-50 tw:focus:ring-red-600"
                            @click="action"
                        >
                            {{ buttonTitle }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ExclamationTriangleIcon} from "@heroicons/vue/20/solid";

const props = defineProps<{
    buttonTitle: string;
    title: string;
    text: string;
    action: () => void;
}>();
const emit = defineEmits<{
    (event: "close"): void;
}>();

const action = (): void => {
    props.action();
    emit("close");
};
</script>
