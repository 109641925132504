<template>
    <TransitionRoot
        as="template"
        :show="open"
        class="hiorg-vue-app"
    >
        <Dialog
            as="div"
            class="tw:fixed tw:z-10 tw:inset-0 tw:overflow-y-auto"
            @close="open = false"
        >
            <div
                class="tw:flex tw:items-center tw:justify-center tw:min-h-screen tw:pt-4 tw:px-4 tw:pb-20 tw:text-center
                       tw:sm:block tw:sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="tw:ease-out tw:duration-300"
                    enter-from="tw:opacity-0"
                    enter-to="tw:opacity-100"
                    leave="tw:ease-in tw:duration-200"
                    leave-from="tw:opacity-100"
                    leave-to="tw:opacity-0"
                >
                    <DialogOverlay class="tw:fixed tw:inset-0 tw:bg-gray-500/75 tw:transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="tw:hidden tw:sm:inline-block tw:sm:align-middle tw:sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="tw:ease-out tw:duration-300"
                    enter-from="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                    enter-to="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                    leave="tw:ease-in tw:duration-200"
                    leave-from="tw:opacity-100 tw:translate-y-0 tw:sm:scale-100"
                    leave-to="tw:opacity-0 tw:translate-y-4 tw:sm:translate-y-0 tw:sm:scale-95"
                >
                    <div
                        class="tw:inline-block tw:align-bottom tw:bg-white tw:rounded-lg tw:px-4 tw:pt-5 tw:pb-4
                                tw:text-left tw:overflow-hidden tw:shadow-xl tw:transform tw:transition-all tw:sm:my-8
                                tw:sm:align-middle tw:sm:max-w-sm tw:sm:w-full tw:sm:p-6"
                    >
                        <div class="tw:mt-3 tw:sm:mt-5">
                            <DialogTitle
                                as="h3"
                                class="tw:text-center tw:text-lg tw:leading-6 tw:font-medium tw:text-gray-900"
                                v-html="titel"
                            />
                            <div class="tw:mt-5">
                                <div
                                    class="tw:text-gray-500"
                                    :class="longText ? 'tw:text-left' : 'tw:text-center'"
                                    v-html="content"
                                />
                            </div>
                        </div>
                        <div class="tw:mt-5 tw:sm:mt-6">
                            <button
                                id="btn-ok"
                                type="button"
                                class="tw:inline-flex tw:justify-center tw:w-full tw:rounded-md
                                    tw:border tw:border-transparent tw:shadow-xs tw:px-4 tw:py-2
                                    tw:font-medium tw:text-white tw:hover:bg-indigo-700 tw:sm:text-sm"
                                @click="open = false"
                                v-html="buttonText"
                            />
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {computed} from "vue";

const props = defineProps<{
    modelValue: boolean;
    titel: string;
    content: string;
    buttonText: string;
}>();
const emit = defineEmits<{
    (event: "update:modelValue", value: boolean): void;
}>();

let longText: boolean = false;

const open = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});

if (props.content.length > 40) {
    longText = true;
}

</script>

<style>
    #btn-ok {
        background-color: #fd5540 !important;
    }
    #btn-ok:hover {
        background-color: #f44336 !important;
    }
</style>
