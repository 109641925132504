<!-- source: https://tailwindui.com/components/application-ui/overlays/notifications -->

<template>
    <div
        class="tw:pointer-events-none tw:fixed tw:inset-0 tw:flex tw:items-end tw:px-4 tw:py-6 tw:sm:items-start tw:sm:p-6"
        aria-live="assertive"
    >
        <div class="tw:flex tw:w-full tw:flex-col tw:items-center tw:space-y-4 tw:sm:items-end">
            <TransitionGroup
                enter-active-class="tw:transform tw:ease-out tw:duration-300 tw:transition"
                enter-from-class="tw:translate-y-2 tw:opacity-0 tw:sm:translate-y-0 tw:sm:translate-x-2"
                enter-to-class="tw:translate-y-0 tw:opacity-100 tw:sm:translate-x-0"
                leave-active-class="tw:transition tw:ease-in tw:duration-100"
                leave-from-class="tw:opacity-100"
                leave-to-class="tw:opacity-0"
            >
                <div
                    v-for="[id, notification] in notifications"
                    :key="id"
                    class="tw:pointer-events-auto tw:w-full tw:max-w-sm tw:overflow-hidden tw:rounded-lg tw:bg-white tw:shadow-lg tw:ring-1 tw:ring-black/5"
                >
                    <div class="tw:p-4">
                        <div class="tw:flex tw:items-start">
                            <div class="tw:shrink-0">
                                <CheckCircleIcon
                                    v-if="notification.type === NotificationTypeEnum.SUCCESS"
                                    class="tw:size-6 tw:text-green-400"
                                    aria-hidden="true"
                                />
                                <ExclamationTriangleIcon
                                    v-if="notification.type === NotificationTypeEnum.ERROR"
                                    class="tw:size-6 tw:text-red-400"
                                    aria-hidden="true"
                                />
                                <ExclamationTriangleIcon
                                    v-if="notification.type === NotificationTypeEnum.WARNING"
                                    class="tw:size-6 tw:text-yellow-400"
                                    aria-hidden="true"
                                />
                                <InformationCircleIcon
                                    v-if="notification.type === NotificationTypeEnum.INFO"
                                    class="tw:size-6 tw:text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="tw:ml-3 tw:w-0 tw:flex-1 tw:pt-0.5">
                                <p class="tw:font-semibold">
                                    <!-- class abweichend -->
                                    {{ getTitle(notification.type) }}
                                </p>
                                <p class="tw:mt-1">
                                    <!-- class abweichend -->
                                    {{ notification.text }}
                                </p>
                            </div>
                            <div class="tw:ml-4 tw:flex tw:shrink-0">
                                <button
                                    type="button"
                                    class="tw:inline-flex tw:rounded-md tw:bg-white tw:text-gray-400 tw:hover:text-gray-500
                                           tw:focus:outline-hidden tw:focus:ring-2 tw:focus:ring-indigo-500 tw:focus:ring-offset-2"
                                    @click="notifications.delete(id)"
                                >
                                    <span class="tw:sr-only">Schlie&szlig;en</span>
                                    <XMarkIcon
                                        class="tw:size-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup lang="ts">
import {CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon} from "@heroicons/vue/24/outline";
import {type Ref, inject, ref} from "vue";
import type {NotificationEvent} from "../../../services/NotificationService";
import {NotificationTypeEnum} from "../../../services/NotificationTypeEnum";
import {XMarkIcon} from "@heroicons/vue/20/solid";
import {keyNotificationService} from "../../../injection-keys";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const notificationService = inject(keyNotificationService)!;
const notifications: Ref<Map<number, NotificationEvent>> = ref(new Map<number, NotificationEvent>());

const getTitle = (typ: NotificationTypeEnum): string => {
    switch (typ) {
        case NotificationTypeEnum.ERROR: return "Fehler";
        case NotificationTypeEnum.SUCCESS: return "Erfolg";
        case NotificationTypeEnum.WARNING: return "Warnung";
        case NotificationTypeEnum.INFO: return "Information";
        default: return "Fehler";
    }
};

let nextId = 1;
notificationService
    .onNotificationEventPublished((event: NotificationEvent) => {
        notifications.value.set(nextId++, event);
    });
</script>
