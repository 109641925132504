import type {Insight} from "../../models/api/insight/Insight";
import {insights as insights2024} from "./2024/insights2024";
import {insights as insights2025} from "./2025/insights2025";

const insights: Array<Insight> = [
    ...insights2025,
    ...insights2024,
];

export {
    insights,
};
