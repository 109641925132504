<!-- source: https://tailwindui.com/components/application-ui/feedback/alerts -->

<template>
    <div
        class="tw:rounded-md tw:bg-red-50 tw:p-4"
    >
        <div class="tw:flex">
            <div class="tw:shrink-0">
                <ExclamationTriangleIcon
                    class="tw:size-5 tw:text-red-400"
                    aria-hidden="true"
                />
            </div>
            <div class="tw:ml-3">
                <h3 class="tw:text-sm tw:font-medium tw:text-red-800">
                    Fehler
                </h3>
                <div class="tw:mt-2 tw:text-sm tw:text-red-700">
                    <p
                        v-for="cause in errorMessages"
                        :key="cause"
                    >
                        {{ cause }}
                    </p>
                </div>
            </div>
            <div
                v-if="hasCloseButton"
                class="tw:ml-auto tw:pl-3"
            >
                <div class="tw:-mx-1.5 tw:-my-1.5">
                    <button
                        type="button"
                        class="tw:inline-flex tw:bg-red-50 tw:rounded-md tw:p-1.5 tw:text-red-800
                               tw:hover:bg-red-100 tw:focus:outline-hidden tw:focus:ring-2 tw:focus:ring-offset-2
                               tw:focus:ring-offset-red-50 tw:focus:ring-red-600"
                        @click="close"
                    >
                        <span class="tw:sr-only">Schließen</span>
                        <XMarkIcon
                            class="tw:size-5"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ExclamationTriangleIcon, XMarkIcon} from "@heroicons/vue/20/solid";

const props = defineProps<{
    errorMessages: Array<string>;
    hasCloseButton: boolean;
}>();
const emit = defineEmits<{
    (event: "close"): void;
}>();

const close = (): void => {
    emit("close");
};

</script>
