<template>
    <div>
        <div>
            <div
                class="tw:w-8 tw:h-8 tw:rounded-full tw:animate-spin tw:border-4 tw:border-dotted
                       tw:border-hiorg-blau-400 tw:border-t-transparent"
            />
        </div>
    </div>
</template>
