<template>
    <div>
        <hr class="tw:mt-6">
        <div class="tw:flex tw:items-center tw:justify-between tw:mt-10">
            <div class="tw:font-semibold">
                Personen w&auml;hlen
            </div>
            <div class="tw:flex tw:items-start">
                <div class="tw:flex tw:items-center tw:h-5">
                    <label
                        class="tw:font-medium tw:text-sm"
                        :class="{'tw:opacity-20': empfaemgerToShow.size === 0}"
                    >
                        <input
                            v-model="alleAusgewaehlt"
                            :disabled="empfaemgerToShow.size === 0"
                            type="checkbox"
                            class="tw:form-checkbox tw:focus:ring-hiorg-teal-500 tw:h-4 tw:w-4 tw:text-hiorg-teal-500
                                   tw:rounded-sm"
                            @change="selectAlleEmpfaenger(alleAusgewaehlt)"
                        >
                        alle ausw&auml;hlen
                    </label>
                </div>
            </div>
        </div>
        <label
            v-if="isFeaturePushAktiv"
            class="tw:flex tw:items-center tw:font-medium tw:mt-2"
            :class="{'tw:opacity-20': anzahlNutzerMitPush === 0}"
        >
            <input
                v-model="alleMitPush"
                :disabled="anzahlNutzerMitPush === 0"
                type="checkbox"
                class="tw:form-checkbox tw:focus:ring-hiorg-teal-500 tw:h-4 tw:w-4 tw:text-hiorg-teal-500
                       tw:rounded-sm tw:mr-1.5"
                @change="selectAlleMitPush(alleMitPush)"
            >
            alle mit aktivem Push auswählen
            <img
                title="Push aktiviert"
                alt="Push aktiviert"
                src="/static/content/pics/glyphicons-2-0/glyphicons-basic-168-mobile-phone-vibrations_9ca3af.svg"
                class="tw:h-6 tw:ml-2 tw:mr-2"
            >
            <span class="tw:text-gray-700 tw:text-sm">
                ({{ anzahlNutzerMitPush }} von {{ filteredEmpfaenger.length }} Personen)
            </span>
        </label>
        <div
            v-for="[section, empfaengerArray] in empfaemgerToShow"
            :key="section"
        >
            <div
                class="tw:w-full tw:border-b tw:mt-6 tw:mb-2 tw:tracking-wide tw:text-gray-400 tw:font-semibold"
            >
                {{ section }}
            </div>
            <div
                class="tw:grid tw:md:grid-cols-2 tw:lg:grid-cols-3 tw:xl:grid-cols-4 tw:2xl:grid-cols-5 tw:w-full"
            >
                <label
                    v-for="empf in empfaengerArray"
                    :key="empf.userId"
                    class="tw:flex tw:items-center tw:p-2 tw:hover:bg-hiorg-teal-100"
                >
                    <input
                        :checked="gewaehlteEmpfaenger.has(empf.userId)"
                        type="checkbox"
                        class="tw:form-checkbox tw:focus:ring-hiorg-teal-500 tw:h-4 tw:w-4 tw:rounded-sm
                           tw:text-hiorg-teal-500 tw:mr-2"
                        @change.stop="toggleEmpfaenger(empf.userId)"
                    >
                    {{ empf.vorname }} {{ empf.name }}
                    <span
                        v-if="isFeaturePushAktiv"
                        class="tw:flex-none tw:ml-2"
                    >
                        <img
                            v-if="empf.isPushAvailable"
                            title="Push aktiviert"
                            alt="Push aktiviert"
                            src="/static/content/pics/glyphicons-2-0/glyphicons-basic-168-mobile-phone-vibrations_9ca3af.svg"
                            class="tw:h-6"
                        >
                        <img
                            v-else
                            title="Push nicht aktiviert"
                            alt="Push nicht aktiviert"
                            src="/static/content/pics/glyphicons-2-0/glyphicons-basic-168-mobile-phone-vibrations_e5e7eb.svg"
                            class="tw:h-6"
                        >
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {type Ref, computed, ref, watch} from "vue";
import type {Empfaenger} from "./Empfaenger";
import type {EmpfaengerFilterSelection} from "./Filter/EmpfaengerFilterSelection";
import {EmpfaengerSelectionFilterer} from "./Filter/EmpfaengerSelectionFilterer";
import {createSectionsForEmpfaenger} from "./createSectionsForEmpfaenger";

const props = defineProps<{
    filterAktiv: EmpfaengerFilterSelection;
    gewaehlteEmpfaenger: Map<string, Empfaenger>;
    isFeaturePushAktiv: boolean;
    potEmpfaenger: Map<string, Empfaenger>;
}>();
const emit = defineEmits<{
    (event: "addEmpfaenger" | "removeEmpfaenger", value: Array<string>): void;
    (event: "setAuchPushSenden", value: boolean): void;
}>();

const alleAusgewaehlt: Ref<boolean> = ref(false);
const alleMitPush: Ref<boolean> = ref(false);

const empfaemgerToShow: Ref<Map<string, Array<Empfaenger>>> = ref(new Map<string, Array<Empfaenger>>());

const filteredEmpfaenger: Ref<Array<Empfaenger>> = computed(
    () => {
        const empfaengerSelectionFilterer = new EmpfaengerSelectionFilterer();
        const potEmpfaengerArray = Array.from(props.potEmpfaenger.values());
        return potEmpfaengerArray
            .filter(
                (empf: Empfaenger) => empfaengerSelectionFilterer.doesEmpfaengerMatchFilterCriteria(
                    empf,
                    props.filterAktiv,
                ),
            );
    },
);

const anzahlNutzerMitPush: Ref<number> = computed(
    () => filteredEmpfaenger.value.filter((empf) => empf.isPushAvailable).length,
);

const initializeView = (): void => {
    empfaemgerToShow.value = createSectionsForEmpfaenger(filteredEmpfaenger.value);

    if (filteredEmpfaenger.value.length === 0) {
        alleMitPush.value = false;
        alleAusgewaehlt.value = false;
        return;
    }

    let alleMitPushCheck: boolean = true;
    let alleGewaehltCheck: boolean = true;

    for (const empfaenger of filteredEmpfaenger.value) {
        if (!props.gewaehlteEmpfaenger.has(empfaenger.userId)) {
            alleGewaehltCheck = false;
            if (empfaenger.isPushAvailable) {
                alleMitPushCheck = false;
            }
        }
    }
    alleMitPush.value = !props.isFeaturePushAktiv
    || filteredEmpfaenger.value.filter((empf) => empf.isPushAvailable).length === 0
        ? false
        : alleMitPushCheck;
    alleAusgewaehlt.value = alleGewaehltCheck;
};

const toggleEmpfaenger = (userId: string): void => {
    if (props.gewaehlteEmpfaenger.has(userId)) {
        emit("removeEmpfaenger", [userId]);
    } else {
        emit("addEmpfaenger", [userId]);
    }
};

initializeView();


watch([filteredEmpfaenger, props.gewaehlteEmpfaenger], () => {
    initializeView();
}, {deep: true});

const emitSelectedEmpfaenger = (checked: boolean, push: boolean): void => {
    const userIds = [];
    for (const empfaenger of filteredEmpfaenger.value) {
        if (push) {
            if (empfaenger.isPushAvailable) {
                userIds.push(empfaenger.userId);
            }
        } else {
            userIds.push(empfaenger.userId);
        }
    }

    if (checked) {
        emit("addEmpfaenger", userIds);
    } else {
        emit("removeEmpfaenger", userIds);
    }
};

const selectAlleEmpfaenger = (checked: boolean): void => {
    emitSelectedEmpfaenger(checked, false);
};

const selectAlleMitPush = (checked: boolean): void => {
    emitSelectedEmpfaenger(checked, true);
    if (checked) {
        emit("setAuchPushSenden", true);
    }
};

</script>
