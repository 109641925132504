<template>
    <div class="tw:relative">
        <span
            v-if="!context.lastInsightGelesen"
            class="tw:absolute tw:-top-1 tw:-right-1 tw:block tw:h-4 tw:w-4 tw:rounded-full tw:z-10"
            style="background-color: #00c3d0;"
        />
        <div
            class="tw:flex tw:items-center tw:justify-center tw:h-11 tw:w-11 nav-button-round tw:cursor-pointer"
            title="Insights öffnen"
            @click="navigateToInsightsPage"
        >
            <FontAwesomeIcon
                v-if="context.lastInsightGelesen"
                icon="fa-regular fa-lightbulb"
                class="tw:text-[#353a41]"
                size="2xl"
            />
            <FontAwesomeLayers
                v-else
                class="tw:text-hiorg-gold-500 fa-2xl tw:m-[-1em]"
                fixed-width
            >
                <FontAwesomeIcon
                    icon="fa-solid fa-lightbulb-exclamation"
                />
                <FontAwesomeIcon
                    icon="fa-solid fa-lightbulb-exclamation-on"
                    fade
                    style="--fa-fade-opacity: 0; --fa-animation-duration: 2s; --fa-animation-timing: ease-in-out;"
                />
            </FontAwesomeLayers>
        </div>
    </div>
</template>

<script setup lang="ts">
import {FontAwesomeLayers} from "@fortawesome/vue-fontawesome";
import {inject} from "vue";
import {keyContext} from "../../injection-keys";

const navigateToInsightsPage = (): void => {
    window.location.href = "insights.php";
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const context = inject(keyContext)!;
</script>
