import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20250318-tnmailag",
        datum: "18.03.2025",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Mailversand bei Kursteilnahme für ein Unternehmen (externe TN-Anmeldung)",
        lizenz: [LizenzEnum.KURSE],
        content: [
            "Bei einer Kursteilnahme (AG / BG) über die öffentliche TN-Anmeldung wird nun auch die Kontaktperson im"
            + " betreffenden Unternehmen per E-Mail über die Teilnahme informiert."
            + " Der Versand kann in den Einstellungen zum öffentlichen Kursangebot deaktiviert werden.",
            "👉 VERSAND ABRECHNUNGSFORMULAR<br>"
            + "Das Abrechnungsformular der BG wird bei einer Anmeldung nun zusätzlich an den Arbeitgeber,"
            + " und nicht nur an die teilnehmende Person verschickt.",
            "👉 NEUE MAILVORLAGEN (Einstellungen > Systemmails)<br>"
            + "<ul class='tw:list-inside tw:list-disc'>"
            + "<li>Es gibt 2 neue Vorlagen für die Benachrichtigungsmail an den Arbeitgeber (mit BG-Abrechnung / ohne BG-Abrechnung).</li>"
            + "<li>Der Standardtext der Bestätigungsmail für Teilnehmende (mit BG-Abrechnung / ohne BG-Abrechnung) wurde angepasst.</li>"
            + "</ul>",
        ],
        aktions: [],
    },
    {
        id: "20250318-bugfixes",
        datum: "18.03.2025",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.BUGFIX,
        title: "Allgemeine Bugfixes",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE],
        content: [
            "<ul>"
            + "<li>📄 Ausgabe der Formular-Variablen <em>$sanis</em> und <em>$dauer</em> auf Formularen zu einzelnen Schichten / Abschnitten korrigiert.</li>"
            + "<li>📦 Material hält sich nicht länger irrtümlich für einen Lagerort.</li>"
            + "<li>🗓️ Regelmäßige Überprüfungen können wieder gelöscht werden.</li>"
            + "<li>🗺️ Bei der Anzeige von Adressen auf der Karte wird die Hausnummer korrket berücksichtigt.</li>"
            + "<li>🎓 Bei der Teilnehmer-Selbstverwaltung wird nicht länger unnötig ein neues Abrechnungsformular gefordert.</li>"
            + "</ul>",
        ],
        aktions: [],
    },
];

export {
    insights,
};
