import "./style.css";
import * as Sentry from "@sentry/vue";
import {FontAwesomeIcon, FontAwesomeLayers} from "@fortawesome/vue-fontawesome";
import {createApp, reactive, readonly} from "vue";
import {
    faArrowUpRightFromSquare,
    faBug,
    faCircleInfo,
    faDesktop,
    faMicrophoneStand,
    faMobileScreenButton,
    faSparkles,
    faUnlock,
    faUserGroup,
    faWrench,
} from "@fortawesome/pro-light-svg-icons";
import {
    faChalkboardUser,
    faCheckCircle,
    faLightbulbExclamation,
    faLightbulbExclamationOn,
    faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {keyContext, keyEndpointFactory, keyNotificationService} from "./injection-keys";
import BenachrichtigungenListe from "./components/benachrichtigungen/BenachrichtigungenListe.vue";
import Context from "./Context";
import EndpointFactory from "./models/api/EndpointFactory";
import FreieBenachrichtigung from "./components/freieBenachrichtigung/FreieBenachrichtigung.vue";
import InsightsList from "./components/Insights/InsightsList.vue";
import NavBar from "./components/navbar/NavBar.vue";
import {NotificationService} from "./services/NotificationService";
import Organisation from "./models/api/Organisation";
import TodoInfoPage from "./components/todoInfoPage/TodoInfoPage.vue";
import User from "./models/api/User";
import {
    faLightbulb,
} from "@fortawesome/pro-regular-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";

library.add(
    faArrowUpRightFromSquare,
    faBug,
    faChalkboardUser,
    faCheckCircle,
    faCircleInfo,
    faDesktop,
    faLightbulb,
    faLightbulbExclamation,
    faLightbulbExclamationOn,
    faMicrophoneStand,
    faMobileScreenButton,
    faSparkles,
    faUnlock,
    faUsers,
    faUserGroup,
    faWrench,
);

const context = reactive(new Context());
const notificationService = new NotificationService();

interface IContextJSON {
    organisation?: Record<string, string>;
    simulated: boolean;
    user?: Record<string, string>;
    last_insight_gelesen: boolean;
}

const setContext = (contextData: IContextJSON): void => {
    const user = new User();
    if (contextData.user) {
        user.setUserId(contextData.user.user_id);
        user.setUserName(contextData.user.username);
        user.setName(contextData.user.name);
        user.setVorName(contextData.user.vorname);
    }
    const organisation = new Organisation();
    if (contextData.organisation) {
        organisation.setBezeichnung(contextData.organisation.bezeichnung);
        organisation.setKuerzel(contextData.organisation.kuerzel);
    }
    context.organisation = organisation;
    context.user = user;
    context.simulated = Boolean(contextData.simulated);
    context.lastInsightGelesen = Boolean(contextData.last_insight_gelesen);
};

const mountVueApp = (div: string): void => {
    const app = createApp({
        name: "App",
        components: {
            NavBar,
            BenachrichtigungenListe,
            FreieBenachrichtigung,
            TodoInfoPage,
            InsightsList,
        },
    });
    app.component("FontAwesomeIcon", FontAwesomeIcon);
    app.component("FontAwesomeLayers", FontAwesomeLayers);
    app.provide(keyContext, readonly(context) as Readonly<Context>);
    app.provide(keyEndpointFactory, new EndpointFactory());
    app.provide(keyNotificationService, notificationService);

    Sentry.attachErrorHandler(
        app,
        {
            attachErrorHandler: true,
            attachProps: true,
            tracingOptions: {
                hooks: [],
                timeout: 0,
                trackComponents: false,
            },
        },
    );

    app.mount(div);
};

const initSentry = (options: Sentry.BrowserOptions): void => {
    Sentry.init(options);
};

export {mountVueApp, setContext, initSentry};
