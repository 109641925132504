<template>
    <div
        class="insight tw:p-2"
        :data-id="props.insight.id"
    >
        <div class="tw:flex tw:items-center">
            <FontAwesomeIcon
                :icon="icon.icon"
                class="tw:mr-2.5"
                :class="icon.farbe"
                size="lg"
            />
            <span
                class="tw:font-medium tw:text-xl"
                v-html="props.insight.title"
            />
        </div>
        <div class="tw:ml-8 tw:mt-1">
            <div class="tw:text-hiorg-blau-400 tw:text-sm">
                HiOrg-Server {{ insight.lizenz.join(" | ") }}
            </div>
            <div
                v-for="(content, index) in insight.content"
                :key="index"
                class="tw:mt-2"
                v-html="content"
            />
            <div
                v-for="(aktion, index) in insight.aktions"
                :key="index"
                class="tw:pt-1 tw:inline-block"
            >
                <InsightActionTypebot
                    v-if="aktion.type === AktionTypeEnum.TYPEBOT"
                    :aktion="aktion"
                    :insight-id="insight.id"
                />
                <InsightAction
                    v-else
                    :aktion="aktion"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {AktionTypeEnum} from "../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../models/api/insight/Insight";
import InsightAction from "./InsightAction.vue";
import InsightActionTypebot from "./InsightActionTypebot.vue";
import {iconInsightTypeMapper} from "../../utilities/Insight/IconMapper";

const props = defineProps<{
    insight: Insight;
}>();
const icon = iconInsightTypeMapper(props.insight.type);
</script>
