<template>
    <SwitchGroup
        as="div"
        class="tw:flex tw:items-center"
    >
        <Switch
            v-model="enabled"
            :class="[enabled ? 'tw:bg-hiorg-teal-500' : 'tw:bg-gray-200',]"
            class="tw:inline-flex tw:shrink-0 tw:h-6 tw:w-11 tw:border-2
                   tw:border-transparent tw:rounded-full tw:cursor-pointer tw:transition-colors
                   tw:ease-in-out tw:duration-200 tw:focus:outline-hidden tw:focus:ring-2
                   tw:focus:ring-offset-2 tw:focus:ring-hiorg-teal-500"
        >
            <span
                aria-hidden="true"
                :class="[enabled ? 'tw:translate-x-5' : 'tw:translate-x-0']"
                class="tw:pointer-events-none tw:inline-block tw:h-5 tw:w-5
                       tw:rounded-full tw:bg-white tw:shadow-sl tw:transform tw:ring-0 tw:transition
                       tw:ease-in-out  tw:duration-200"
            />
        </Switch>
        <SwitchLabel
            as="span"
            class="tw:ml-3"
        >
            {{ text }}
        </SwitchLabel>
    </SwitchGroup>
</template>

<script setup lang="ts">
import {Switch, SwitchGroup, SwitchLabel} from "@headlessui/vue";
import {computed} from "vue";

const props = defineProps<{
    text: string;
    modelValue: boolean;
}>();

const emit = defineEmits<{
    (event: "update:modelValue", value: boolean): void;
}>();

const enabled = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});
</script>

