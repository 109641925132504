<template>
    <p
        class="tw:mb-10"
    >
        <span class="tw:font-semibold tw:text-xl">Willkommen in unserem neuen Insights-Bereich!</span><br>
        Hier informieren wir Dich &uuml;ber die neuesten Funktionen, Bugfixes, Unternehmensnews und Aktionen, damit Du
        stets auf dem neuesten Stand bist und das Beste aus HiOrg-Server herausholen kannst.
    </p>
    <div ref="insights-list">
        <template
            v-for="[datum, insightsAll] in insightsMap.entries()"
            :key="datum"
        >
            <InsightsCard
                v-for="[kategorie, insightsKategorie] in insightsAll.entries()"
                :key="kategorie"
                :datum="datum"
                :kategorie="kategorie"
                :insights="insightsKategorie"
                class="tw:mb-10"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
import {onMounted, useTemplateRef} from "vue";
import type {Insight} from "../../models/api/insight/Insight";
import InsightsCard from "./InsightsCard.vue";
import type {KategorieEnum} from "../../models/api/insight/KategorieEnum";
import {insights} from "../../data/Insights/Insights";

const list = useTemplateRef<HTMLElement>("insights-list");

const insightsMap = new Map<string, Map<KategorieEnum, Array<Insight>>>();
for (const insight of insights) {
    if (!insightsMap.has(insight.datum)) {
        insightsMap.set(insight.datum, new Map<KategorieEnum, Array<Insight>>());
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const kategorieMap = insightsMap.get(insight.datum)!;
    if (!kategorieMap.has(insight.kategorie)) {
        kategorieMap.set(insight.kategorie, new Array<Insight>());
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    kategorieMap.get(insight.kategorie)!.push(insight);
}

onMounted(() => {
    const scrollId = /^#([a-z0-9-]+)$/u.exec(window.location.hash)?.[1] ?? null;
    if (scrollId === null) {
        return;
    }
    const insight: HTMLElement | null = list.value.querySelector(`.insight[data-id='${scrollId}']`);
    if (insight === null) {
        return;
    }
    insight.classList.add("tw:border-l", "tw:border-hiorg-teal-500");
    insight.closest(".insights-card")?.scrollIntoView({behavior: "smooth"});
});
</script>
