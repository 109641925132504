<template>
    <div class="tw:flex tw:flex-wrap">
        <label class="tw:whitespace-nowrap">
            ab
            <select
                v-model="qualiMin"
                name="qualifikation1"
                class="tw:w-56 tw:border-gray-300 tw:focus:outline-hidden
                       tw:focus:ring-hiorg-teal-500 tw:focus:border-hiorg-teal-500 tw:rounded-md
                       tw:form-select tw:mx-2 tw:text-gray-700"
            >
                <option
                    :value="null"
                >
                    -- keine Auswahl --
                </option>
                <option
                    v-for="entry in list.values()"
                    :key="entry.id"
                    :value="entry.id"
                >
                    {{ entry.bezeichnung }}
                </option>
            </select>
        </label>

        <label class="tw:whitespace-nowrap">
            bis
            <select
                v-model="qualiMax"
                name="qualifikation2"
                class="tw:w-56 tw:border-gray-300 tw:focus:outline-hidden
                       tw:focus:ring-hiorg-teal-500 tw:focus:border-hiorg-teal-500 tw:rounded-md
                       tw:form-select tw:mx-2 tw:text-gray-700"
            >
                <option
                    :value="null"
                >
                    -- keine Auswahl --
                </option>
                <option
                    v-for="entry in list.values()"
                    :key="entry.id"
                    :value="entry.id"
                >
                    {{ entry.bezeichnung }}
                </option>
            </select>
        </label>
    </div>
</template>

<script setup lang="ts">
import type {Qualifikation} from "../../models/business/Qualifikation";
import {computed} from "vue";

const props = defineProps<{
    list: Map<number, Qualifikation>;
    start: number | null;
    end: number | null;
}>();

const emit = defineEmits<{
    (event: "update:end" | "update:start", value: number | null): void;
}>();

const qualiMin = computed({
    get() {
        return props.start;
    },
    set(value: number | null) {
        emit("update:start", value);
        if (value !== null && props.end !== null && value > props.end) {
            emit("update:end", value);
        }
    },
});

const qualiMax = computed({
    get() {
        return props.end;
    },
    set(value: number | null) {
        emit("update:end", value);
        if (value !== null && props.start !== null && props.start > value) {
            emit("update:start", value);
        }
    },
});

</script>
