<template>
    <Switch
        v-model="enabled"
        :style="style"
        class="tw:inline-flex tw:px-3 tw:py-1 tw:rounded-full tw:font-medium"
    >
        {{ text }}
    </Switch>
</template>

<script setup lang="ts">
import {Switch} from "@headlessui/vue";
import {computed} from "vue";

const props = defineProps<{
    color: string;
    text: string;
    modelValue: boolean;
}>();
const emit = defineEmits<{
    (event: "update:modelValue", value: boolean): void;
}>();

const enabled = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});

const style = computed(
    () => enabled.value
        ? {color: "white", backgroundColor: props.color, border: "1px solid", borderColor: props.color}
        : {color: props.color, backgroundColor: "white", border: "1px solid", borderColor: props.color},
);
</script>
