<template>
    <div>
        <div class="tw:mt-1">
            <textarea
                v-model="innerText"
                rows="4"
                class="tw:shadow-xs tw:focus:ring-hiorg-teal-500 tw:focus:border-hiorg-teal-500 tw:block tw:w-full
                       tw:border-gray-400 tw:rounded-md tw:form-textarea
                       tw:font-mono tw:text-gray-700 tw:min-h-20"
                :placeholder="placeHolder"
                :maxlength="maxCount"
            />
        </div>
        <div class="tw:flex tw:justify-end tw:whitespace-nowrap">
            <span :class="count > maxCount ? 'tw:text-hiorg-hellrot-500 tw:font-semibold' : 'tw:text-hiorg-teal-500'">
                {{ count }}
            </span>
            &nbsp;/&nbsp;
            <span class="tw:text-gray-700">{{ maxCount }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import {type Ref, computed} from "vue";

const props = defineProps<{
    maxCount: number;
    placeHolder: string;
    text: string;
}>();

const emit = defineEmits<{
    (event: "update:text", value: string): void;
}>();

const innerText = computed({
    get() {
        return props.text;
    },
    set(value: string) {
        emit("update:text", value);
    },
});

const count: Ref<number> = computed(() => innerText.value.length);
</script>

