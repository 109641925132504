<template>
    <div class="tw:px-2 tw:sm:px-0">
        <BasicDisclosure
            titel="Filtern und auswählen"
        >
            <TabGroup
                @change="changeTab"
            >
                <TabList class="tw:flex tw:space-x-1 tw:border-b tw:border-gray-200">
                    <Tab
                        v-for="tab in tabs.values()"
                        :key="tab.id"
                        v-slot="{ selected }"
                        as="template"
                    >
                        <button
                            :class="[
                                'tw:flex tw:justify-center tw:py-2.5 tw:text-lg ' +
                                    'tw:font-semibold tw:leading-5 tw:focus:outline-hidden tw:w-48',
                                selected
                                    ? 'tw:border-b-2 tw:border-b-hiorg-teal-500 tw:text-hiorg-teal-500'
                                    : 'tw:text-gray-400 tw:hover:text-gray-500 tw:hover:border-b-2 ' +
                                        'tw:hover:border-b-gray-300',
                            ]"
                            type="button"
                        >
                            <FontAwesomeIcon
                                :icon="tab.icon"
                                class="tw:h-5 tw:w-5 tw:mr-2"
                            />
                            {{ tab.title }}
                        </button>
                    </Tab>
                </TabList>

                <TabPanels class="tw:mt-6">
                    <TabPanel>
                        <ToggleWithLabel
                            v-model="filterAlleMitglieder.isLeitungsteamSelected"
                            text="nur Leitungsteam anzeigen"
                            class="tw:mt-8"
                        />
                        <div v-show="!filterAlleMitglieder.isLeitungsteamSelected">
                            <MultipleBadgeSelection
                                v-model:user-selection="filterAlleMitglieder.gruppenSelection"
                                titel="Gruppen wählen"
                                :badges="gruppenTmp"
                                class="tw:mt-8"
                            />
                            <div
                                v-if="stammdaten.qualiListe1 !== null"
                                class="tw:mt-8 tw:font-semibold"
                            >
                                {{ stammdaten.qualiListe1.bezeichnung }}
                            </div>
                            <QualificationSelectionRow
                                v-if="stammdaten.qualiListe1 !== null"
                                v-model:start="filterAlleMitglieder.quali1Selections.start"
                                v-model:end="filterAlleMitglieder.quali1Selections.end"
                                :list="stammdaten.qualiListe1.qualiliste"
                                class="tw:mt-2"
                            />
                            <div
                                v-if="stammdaten.qualiListe2 !== null"
                                class="tw:mt-5 tw:font-semibold"
                            >
                                {{ stammdaten.qualiListe2.bezeichnung }}
                            </div>
                            <QualificationSelectionRow
                                v-if="stammdaten.qualiListe2 !== null"
                                v-model:start="filterAlleMitglieder.quali2Selections.start"
                                v-model:end="filterAlleMitglieder.quali2Selections.end"
                                :list="stammdaten.qualiListe2.qualiliste"
                                class="tw:mt-2"
                            />
                            <div
                                v-if="stammdaten.qualiListe3 !== null"
                                class="tw:mt-5 tw:font-semibold"
                            >
                                {{ stammdaten.qualiListe3.bezeichnung }}
                            </div>
                            <QualificationSelectionRow
                                v-if="stammdaten.qualiListe3 !== null"
                                v-model:start="filterAlleMitglieder.quali3Selections.start"
                                v-model:end="filterAlleMitglieder.quali3Selections.end"
                                :list="stammdaten.qualiListe3.qualiliste"
                                class="tw:mt-2"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <MultipleBadgeSelection
                            v-model:user-selection="filterLehrkraefte.lehrscheineSelection"
                            titel="Lehrscheine wählen"
                            :badges="lehrscheineTmp"
                        />
                    </TabPanel>
                </TabPanels>
            </TabGroup>
            <EmpfaengerAuswahl
                :gewaehlte-empfaenger="gewaehlteEmpfaenger"
                :pot-empfaenger="empfaenger"
                :filter-aktiv="filterAktiv"
                :is-feature-push-aktiv="stammdaten.isFeaturePushAktiv"
                class="tw:mt-8"
                @add-empfaenger="(value) => emit('addEmpfaenger', value)"
                @remove-empfaenger="(value) => emit('removeEmpfaenger', value)"
                @set-auch-push-senden="(value) => emit('setAuchPushSenden', value)"
            />
        </BasicDisclosure>
    </div>
</template>

<script setup lang="ts">
import {type Ref, ref, watch} from "vue";
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@headlessui/vue";
import BasicDisclosure from "../common/BasicDisclosure.vue";
import type {Empfaenger} from "./Empfaenger";
import EmpfaengerAuswahl from "./EmpfaengerAuswahl.vue";
import {EmpfaengerFilterSelection} from "./Filter/EmpfaengerFilterSelection";
import type {FreieBenachrichtigungStammdaten} from "./FreieBenachrichtigungStammdaten";
import MultipleBadgeSelection from "../common/MultipleBadgeSelection.vue";
import QualificationSelectionRow from "../common/QualificationSelectionRow.vue";
import ToggleWithLabel from "../common/ToggleWithLabel.vue";

const props = defineProps<{
    gewaehlteEmpfaenger: Map<string, Empfaenger>;
    empfaenger: Map<string, Empfaenger>;
    stammdaten: FreieBenachrichtigungStammdaten;
}>();
const emit = defineEmits<{
    (event: "addEmpfaenger" | "removeEmpfaenger", value: Array<string>): void;
    (event: "setAuchPushSenden", value: boolean): void;
}>();

const filterAlleMitglieder: Ref<EmpfaengerFilterSelection> = ref(new EmpfaengerFilterSelection());
const filterLehrkraefte: Ref<EmpfaengerFilterSelection> = ref(new EmpfaengerFilterSelection());
filterLehrkraefte.value.lehrscheineSelection = new Array<number>();
const filterAktiv: Ref<EmpfaengerFilterSelection> = ref(filterAlleMitglieder.value);

interface Tabs {
    id: number;
    title: string;
    icon: string;
}

const tabs: Ref<Array<Tabs>> = ref(new Array<Tabs>());
tabs.value.push({
    id: 0,
    title: "Alle Mitglieder",
    icon: "fa-solid fa-users",
});
if (props.stammdaten.lehrscheine!.size > 0) {
    tabs.value.push({
        id: 1,
        title: "Lehrkräfte",
        icon: "fa-solid fa-chalkboard-user",
    });
}

const gruppenTmp: Array<{id: number; bezeichnung: string}> = [];
for (const gruppe of props.stammdaten.gruppen.values()) {
    gruppenTmp.push({id: gruppe.id, bezeichnung: gruppe.bezeichnung});
}
gruppenTmp.push({id: 0, bezeichnung: "ohne Gruppe"});

const lehrscheineTmp: Array<{id: number; bezeichnung: string}> = [];
if (props.stammdaten.lehrscheine !== null) {
    for (const lehrschein of props.stammdaten.lehrscheine.values()) {
        lehrscheineTmp.push({id: lehrschein.id, bezeichnung: lehrschein.bezeichnung});
    }
}

const changeTab = (index: number): void => {
    if (index === 0) {
        filterAktiv.value = filterAlleMitglieder.value;
    } else {
        filterAktiv.value = filterLehrkraefte.value;
    }
};

watch(
    (): boolean => filterAlleMitglieder.value.isLeitungsteamSelected,
    (): void => {
        filterAlleMitglieder.value.gruppenSelection = [];
        filterAlleMitglieder.value.quali1Selections = {start: null, end: null};
        filterAlleMitglieder.value.quali2Selections = {start: null, end: null};
        filterAlleMitglieder.value.quali3Selections = {start: null, end: null};
    },
);

</script>

