<template>
    <button
        type="button"
        class="tw:rounded-full tw:p-2
            tw:hover:bg-hiorg-blau-200 tw:focus-visible:outline tw:focus-visible:outline-2
            tw:focus-visible:outline-offset-2 tw:focus-visible:outline-hiorg-blau-200"
    >
        <img
            src="/static/content/pics/fontawesome/trash-alt_71757a.svg"
            alt="Löschen"
            title="Löschen"
            class="tw:h-6 tw:cursor-pointer"
        >
    </button>
</template>
