<template>
    <div class="insights-card tw:p-5 tw:bg-white">
        <div class="tw:flex tw:w-full tw:pb-1 tw:border-b tw:border-hiorg-teal-500 tw:justify-between tw:items-center">
            <div class="tw:flex tw:items-center">
                <FontAwesomeIcon
                    :icon="icon.icon"
                    class="tw:mr-2"
                    :class="icon.farbe"
                    size="lg"
                    title="Kategorie"
                />
                <span
                    class="tw:text-hiorg-teal-500 tw:font-semibold tw:text-xl"
                >
                    {{ props.kategorie }}
                </span>
            </div>
            <div class="tw:flex">
                {{ props.datum }}
            </div>
        </div>
        <div class="tw:flex tw:flex-col tw:gap-4 tw:mt-4">
            <InsightItem
                v-for="insight in props.insights"
                :key="insight.id"
                :insight="insight"
                class="tw:-m-2"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import type {Insight} from "../../models/api/insight/Insight";
import InsightItem from "./InsightItem.vue";
import type {KategorieEnum} from "../../models/api/insight/KategorieEnum";
import {iconKategorieMapper} from "../../utilities/Insight/IconMapper";

const props = defineProps<{
    kategorie: KategorieEnum;
    datum: string;
    insights: Array<Insight>;
}>();

const icon = iconKategorieMapper(props.kategorie);
</script>
