<template>
    <div class="tw:bg-white tw:overflow-hidden tw:shadow-sl tw:rounded-lg">
        <div class="tw:px-4 tw:py-5 tw:sm:p-6">
            <FreieBenachrichtigungFilter
                :gewaehlte-empfaenger="empfaengerGewaehlt"
                :empfaenger="potEmpfaenger"
                :stammdaten="stammdaten"
                @add-empfaenger="onEmpfaengerAdd"
                @remove-empfaenger="onEmpfaengerRemove"
                @set-auch-push-senden="setAuchPushSenden"
            />
            <EmpfaengerSuche
                :gewaehlte-empfaenger="empfaengerGewaehlt"
                :empfaenger="potEmpfaenger"
                placeholder="Person hinzufügen"
                class="tw:mt-10"
                @add-empfaenger="onEmpfaengerAdd"
                @remove-empfaenger="onEmpfaengerRemove"
            />
            <div class="tw:mt-10 tw:ml-2 tw:font-semibold">
                Nachricht
            </div>
            <TextareaWithCounter
                v-model:text="nachricht"
                :max-count="500"
                place-holder="Text hinzufügen"
            />
            <label
                v-if="stammdaten.isFeaturePushAktiv"
                class="tw:font-medium tw:h-5"
                :class="{'tw:opacity-20': anzahlAusgewaehlteMitPush === 0}"
            >
                <input
                    v-model="auchPushSenden"
                    :disabled="anzahlAusgewaehlteMitPush === 0"
                    type="checkbox"
                    class="tw:form-checkbox tw:focus:ring-hiorg-teal-500 tw:h-4 tw:w-4 tw:rounded-sm
                               tw:text-hiorg-teal-500"
                >
                auch als Push-Benachrichtigung an {{ labelPushSenden }} ausliefern
            </label>
        </div>
        <div class="tw:flex tw:justify-end tw:bg-gray-50 tw:px-4 tw:py-4 tw:sm:px-6">
            <PrimaryButton
                :text="'Nachricht an ' + empfaengerGewaehlt.size +
                    (empfaengerGewaehlt.size === 1 ? ' Person' : ' Personen') + ' senden'"
                :set-disabled="empfaengerGewaehlt.size === 0 || nachricht.length === 0"
                @click="senden"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {type Ref, inject, ref, watch} from "vue";
import {keyEndpointFactory, keyNotificationService} from "../../injection-keys";
import {BaseException} from "../../BaseException";
import type {Empfaenger} from "./Empfaenger";
import EmpfaengerSuche from "./EmpfaengerSuche.vue";
import type {FreieBenachrichtigung} from "../../models/api/freieBenachrichtigung/FreieBenachrichtigung";
import type {FreieBenachrichtigungEndpoint} from "../../models/api/freieBenachrichtigung/FreieBenachrichtigungEndpoint";
import FreieBenachrichtigungFilter from "./FreieBenachrichtigungFilter.vue";
import type {FreieBenachrichtigungStammdaten} from "./FreieBenachrichtigungStammdaten";
import type {NotificationEvent} from "../../services/NotificationService";
import {NotificationTypeEnum} from "../../services/NotificationTypeEnum";
import PrimaryButton from "../common/PrimaryButton.vue";
import TextareaWithCounter from "../common/TextareaWithCounter.vue";
import {parseEmpfaengerListe} from "./parseEmpfaengerListe";
import {parseStammdaten} from "../../utilities/common/parseStammdaten";

const empfaengerGewaehlt: Ref<Map<string, Empfaenger>> = ref(new Map<string, Empfaenger>());
const labelPushSenden: Ref<string> = ref("0 Personen");
const auchPushSenden: Ref<boolean> = ref(false);
const nachricht: Ref<string> = ref("");
const stammdaten = parseStammdaten<FreieBenachrichtigungStammdaten>("stammdaten");
const potEmpfaenger = parseEmpfaengerListe(document.getElementById("empfaenger")?.textContent ?? "[]");
const anzahlAusgewaehlteMitPush: Ref<number> = ref(0);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const endpointFactory = inject(keyEndpointFactory)!;
const freieBenachrichtigungEndpoint: FreieBenachrichtigungEndpoint = endpointFactory.getFreieBenachrichtigungEndpoint();
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const notificationService = inject(keyNotificationService)!;

const onEmpfaengerAdd = (userIds: Array<string>): void => {
    for (const userId of userIds) {
        const foundEmpfaenger = potEmpfaenger.get(userId);
        if (foundEmpfaenger) {
            empfaengerGewaehlt.value.set(foundEmpfaenger.userId, foundEmpfaenger);
        }
    }
};

const onEmpfaengerRemove = (userIds: Array<string>): void => {
    for (const userId of userIds) {
        empfaengerGewaehlt.value.delete(userId);
    }
};

const setAuchPushSenden = (): void => {
    auchPushSenden.value = true;
};

const senden = (): void => {
    if (empfaengerGewaehlt.value.size === 0) {
        notificationService
            .notifyForEevent(
                {type: NotificationTypeEnum.ERROR, text: "Keine Empfänger gewählt!"} as NotificationEvent,
            );
        return;
    }
    if (nachricht.value.length === 0) {
        notificationService
            .notifyForEevent(
                {type: NotificationTypeEnum.ERROR, text: "Keine Nachricht eingegeben!"} as NotificationEvent,
            );
        return;
    }


    const empfaengerUserIds: Array<string> = [];
    for (const empf of empfaengerGewaehlt.value.values()) {
        empfaengerUserIds.push(empf.userId);
    }

    const benachrichtigung: FreieBenachrichtigung = {
        empfaenger: empfaengerUserIds,
        pushSenden: stammdaten.isFeaturePushAktiv ? auchPushSenden.value : false,
        text: nachricht.value,
    };

    freieBenachrichtigungEndpoint
        .sendBenachrichtigung(benachrichtigung)
        .then((anzahlGesendet: number) => {
            notificationService
                .notifyForEevent(
                    {
                        type: NotificationTypeEnum.SUCCESS,
                        text: `Benachrichtigung an ${String(anzahlGesendet)} Person(en) versendet`,
                    } as NotificationEvent,
                );

            // Eingaben zurücksetzen
            nachricht.value = "";
            empfaengerGewaehlt.value.clear();
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                notificationService
                    .notifyForEevent({type: NotificationTypeEnum.ERROR, text: ex.message} as NotificationEvent);
            }
        });
};

watch([empfaengerGewaehlt], (): void => {
    anzahlAusgewaehlteMitPush.value = 0;
    for (const empfaenger of empfaengerGewaehlt.value.values()) {
        if (empfaenger.isPushAvailable) {
            anzahlAusgewaehlteMitPush.value++;
        }
    }
    if (anzahlAusgewaehlteMitPush.value === 0) {
        auchPushSenden.value = false;
    }
    labelPushSenden.value = anzahlAusgewaehlteMitPush.value.toString()
    + (anzahlAusgewaehlteMitPush.value === 1 ? " Person " : " Personen ");
}, {deep: true});

</script>
