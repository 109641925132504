<template>
    <Popover
        v-slot="{ open }"
        class="tw:relative"
    >
        <span
            v-if="!alleBenachrichtigungenGelesen"
            class="tw:absolute tw:-top-1 tw:-right-1 tw:block tw:h-4 tw:w-4 tw:rounded-full tw:z-10 tw:bg-hiorg-teal-500"
        />
        <PopoverButton
            class="tw:flex tw:items-center tw:justify-center tw:h-11 tw:w-11 nav-button-round tw:cursor-pointer"
        >
            <img
                v-if="!open"
                src="/static/content/pics/fontawesome/bell_353a41.svg"
                alt="Benachrichtigugnen öffnen"
                class="tw:h-8 tw:w-8"
                aria-hidden="true"
                title="Benachrichtigungen öffnen"
            >
            <img
                v-else
                src="/static/content/pics/fontawesome/bell-on_353a41.svg"
                alt="Benachrichtigugnen schließen"
                class="tw:block tw:h-8 tw:w-8"
                aria-hidden="true"
                title="Benachrichtigungen schließen"
            >
        </PopoverButton>
        <TransitionRoot
            enter="tw:transition tw:ease-out tw:duration-200"
            enter-from="tw:opacity-0 tw:translate-y-1"
            enter-to="tw:opacity-100 tw:translate-y-0"
            leave-active="tw:transition tw:ease-in tw:duration-150"
            leave-from="tw:opacity-100 tw:translate-y-0"
            leave-to="tw:opacity-0 tw:translate-y-1"
            @after-leave="loadBenachrichtigungen"
        >
            <PopoverPanel
                class="tw:origin-top-right tw:absolute tw:z-10 tw:right-0 tw:mt-2 tw:bg-white tw:border-solid
                       tw:border-gray-400 tw:border-2 tw:w-screen tw:max-w-xl"
            >
                <CardContainer
                    title="Alle Benachrichtigungen anzeigen"
                    link-text="angezeigte gelesen"
                    :additional-title="anzahlBenachrichtigungenText"
                    :is-klickbar="true"
                    :card-title-click-function-parent="navigateToBenachrichtigungenPage"
                    :card-link-function-parent="setAllBenachrichtigungenGelesen"
                    :show-card-link="!alleBenachrichtigungenGelesen"
                >
                    <div
                        class="tw:divide-x-0 tw:divide-y tw:divide-solid tw:divide-gray-400 tw:max-h-96
                               tw:overflow-auto"
                    >
                        <BenachrichtigungMenuEntry
                            v-for="benachrichtigung in benachrichtigungen"
                            :key="benachrichtigung.id"
                            :benachrichtigung="benachrichtigung"
                            :benachrichtigung-loeschen-parent="deleteBenachrichtigung"
                            :benachrichtigung-gelesen-ungelesen="setGelesenUngelesen"
                            class="tw:hover:bg-hiorg-teal-100"
                        />
                    </div>
                    <MenuErrorNotification
                        v-if="errorMessages.length > 0"
                        :error-messages="errorMessages"
                        :has-close-button="true"
                        class="tw:m-2 tw:border-t-0!"
                        @close="closeMenuError"
                    />
                </CardContainer>
                <div
                    v-if="anzahlWeitereUngelesene > 0"
                    class="tw:flex tw:justify-center tw:p-2"
                >
                    <span
                        class="tw:inline-flex tw:items-center tw:px-3 tw:py-0.5 tw:rounded-full tw:text-sm
                                 tw:font-medium tw:bg-gray-100 tw:text-gray-800 tw:cursor-pointer"
                        @click="loadBenachrichtigungen"
                    >
                        {{ anzahlWeitereUngelesene }} weitere ungelesene Benachrichtigungen
                    </span>
                </div>
            </PopoverPanel>
        </TransitionRoot>
    </Popover>
</template>

<script setup lang="ts">
import {Popover, PopoverButton, PopoverPanel, TransitionRoot} from "@headlessui/vue";
import {type Ref, computed, inject, ref} from "vue";
import {BaseException} from "../../BaseException";
import type {BenachrichtigungEndpoint} from "../../models/api/benachrichtigung/BenachrichtigungEndpoint";
import BenachrichtigungMenuEntry from "./BenachrichtigungMenuEntry.vue";
import type {BenachrichtigungQuery} from "../../models/api/benachrichtigung/BenachrichtigungQuery";
import type {BenachrichtigungenResponse} from "../../models/api/benachrichtigung/BenachrichtigungenResponse";
import type {BenachrichtigungenViewModel} from "../benachrichtigungen/BenachrichtigungenViewModel";
import CardContainer from "../common/CardContainer.vue";
import MenuErrorNotification from "../common/error/banner/MenuErrorNotification.vue";
import {
    buildBenachrichtigungenFromResponse,
} from "../../utilities/Benachrichtigungen/BenachrichtigungForFrontEndMapper";
import {keyEndpointFactory} from "../../injection-keys";

const benachrichtigungen: Ref<Array<BenachrichtigungenViewModel>> = ref([]);
const anzahlBenachrichtigungenText: Ref<string> = ref("");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const endpointFactory = inject(keyEndpointFactory)!;
const benachrichtigungEndpoint: BenachrichtigungEndpoint = endpointFactory.getBenachrichtigungEndpoint();
const errorMessages: Ref<Array<string>> = ref([]);
const anzahlWeitereUngelesene: Ref<number> = ref(0);

const alleBenachrichtigungenGelesen = computed(
    (): boolean => benachrichtigungen.value.filter((entry) => !entry.gelesen).length + anzahlWeitereUngelesene.value === 0,
);

const loadBenachrichtigungen = (): void => {
    const benachrichtigungenQuery: BenachrichtigungQuery = {
        page: 1,
        pageSize: 7,
        gelesen: false,
    };

    benachrichtigungEndpoint
        .getBenachrichtigungen(benachrichtigungenQuery)
        .then((benachrichtigungenResponse: BenachrichtigungenResponse) => {
            const anzahlBenachr = benachrichtigungenResponse.getMetaData().size;
            const anzahlUngeleseneGesamt = benachrichtigungenResponse.getMetaData().size_filtered;
            if (anzahlBenachr > 0) {
                anzahlBenachrichtigungenText.value = `(${anzahlBenachr.toString()}) >`;
            }
            const benachrichtigungenViewModel: Array<BenachrichtigungenViewModel>
                = buildBenachrichtigungenFromResponse(benachrichtigungenResponse.getData());
            benachrichtigungen.value = benachrichtigungenViewModel;
            anzahlWeitereUngelesene.value = anzahlUngeleseneGesamt - benachrichtigungenViewModel.length;
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                errorMessages.value.push(ex.message);
            }
        });
};

const navigateToBenachrichtigungenPage = (): void => {
    window.location.href = "benachrichtigungen.php";
};

const closeMenuError = (): void => {
    errorMessages.value = [];
};

const setAllBenachrichtigungenGelesen = (): void => {
    // Link "alle gelesen" (nur die 7 sichtbaren als gelesen markieren).
    // Nach dem Klick wird der nächste Satz ungelesene Benachrichtigungen geladen.
    if (benachrichtigungen.value.length > 0) {
        benachrichtigungEndpoint
            .setAlleGelesen()
            .then(() => {
                loadBenachrichtigungen();
            })
            .catch((ex: unknown) => {
                if (ex instanceof BaseException) {
                    errorMessages.value.push(ex.message);
                }
            })
            .finally(() => {
                loadBenachrichtigungen();
            });
    }
};

const setGelesenUngelesen = (benachrichtigung: BenachrichtigungenViewModel): void => {
    // Nach dem Klick soll die Benachrichtigung in der UI markiert und der Call an die API
    // gemacht werden. Ein Reload soll hier nicht erfolgen.
    benachrichtigungEndpoint
        .toggleBenachrichtigungGelesenUngelesen(benachrichtigung.id)
        .then(() => {
            benachrichtigung.gelesen = !benachrichtigung.gelesen;
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                errorMessages.value.push(ex.message);
            }
        });
};


const deleteBenachrichtigung = (id: string): void => {
    benachrichtigungEndpoint
        .deleteBenachrichtigung(id)
        .then(() => {
            benachrichtigungen.value = benachrichtigungen.value
                .filter((be: BenachrichtigungenViewModel) => be.id !== id);
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                errorMessages.value.push(ex.message);
            }
        });
};

loadBenachrichtigungen();
</script>
